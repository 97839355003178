import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box, Paper, Typography } from "@mui/material";
import CustomAppBar from "../components/PositronAppBar";
import { useLocation } from "react-router-dom";

const styles = {
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        width: '100%',
    },
    
    paper: {
        padding: '50px',
        maxWidth: 'sm',
        margin: '50px auto 0 auto',
        textAlign: 'center',
        boxShadow: 'none'
    },

    shoppingCartIcon: {
        fontSize: '200px',
        color: '#2196f3'
    }

};

export default function ThankYou() {
    const urlSearchParam = new URLSearchParams(useLocation().search);
    const product = decodeURIComponent(urlSearchParam.get("product"));
    let hasProduct = false;
    
    let productName = product;
    if (product) {
        hasProduct = true;
		let productCode = product.toLowerCase();
		if(productCode.startsWith("aip-explorer")) {
          productName = 'Oxygen AI Positron Explorer 1-month subscription';
		} else if(productCode.startsWith("aip-pro")) {
          productName = 'Oxygen AI Positron Professional 1-month subscription';
		} else if(productCode.startsWith("aip-expert")){
            productName = 'Oxygen AI Positron Expert 1-month subscription';
		} else {
            hasProduct = false;
        }
    }

    return (
        <Box sx={styles.box}>
            <CustomAppBar hasError={true} loggedIn={false}/>
            <Paper elevation={3} sx={styles.paper}>
                <ShoppingCartIcon sx={styles.shoppingCartIcon} />
                <Typography variant="h5" gutterBottom>
                    Thank You for Your Purchase!
                </Typography>
                {hasProduct ? 
                    <Typography variant="body1">  The order for <i> {productName} </i>  is being processed.</Typography> 
                    : 
                    <Typography variant="body1">  The order is being processed.</Typography> }
                <Typography variant="body1">A confirmation email will be sent out shortly.</Typography>
            </Paper>
        </Box>
    );
}