import { Typography, Card, CardContent, Button, Box, Tooltip} from '@mui/material';
import React, { useState } from 'react';

/**
 *
 * @param plan
 * @param currentPlan
 * @param allPlans
 * @returns {JSX.Element}
 * @constructor
 */
function SubscriptionPlanCard({plan, currentPlan, allPlans}) {
    /**
     * Creates the styles to be used in the component.
     */
    const styles = {

        disablePlanActionButton: {
            marginTop: "1em",
            color: "#E7E3E3",
            backgroundColor: "#8AA1C1",
            cursor: "default",
            pointerEvents: "none",
            '&:hover': {
                backgroundColor: "#8AA1C1",
            }
        },

        planActionButton: {
            marginTop: "1em",
            marginLeft: ".3em",
            marginRight: ".3em",
            color: "primary",
            whiteSpace: "nowrap", // Prevent text from wrapping inside the button
            textAlign: 'center',
            overflow: 'visible', // Ensure the text content is fully visible
            display: "inline-block",
            minWidth: "155px",
        },

        planButtonsContainer: {
            marginTop: "1em",
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
    };

    let actionButton;
    const isEvaluation =  currentPlan.planName.toLowerCase() === "evaluation";
    const isSelectedPlan =  plan.planName.toLowerCase() === currentPlan.planName.toLowerCase();
    const isCancelled = currentPlan.cancelled;
    const isExpiredAndBeyondRenew = currentPlan.isExpired && !currentPlan.canBuyRenewLicense;

    const [textCopied, setTextCopied] = useState(false);


    if(isEvaluation || isCancelled || isExpiredAndBeyondRenew) {
        actionButton = (
          
          <Box sx={styles.planButtonsContainer}>
          
            <Button variant="contained" href={`/checkout/subscribe?plan=${plan.id}`} sx={styles.planActionButton}>
                Subscribe
            </Button>
            <Tooltip title={
				<Typography variant="caption">
					If someone else in your company handles subscription purchases, use this button to share the purchase link with them.
				</Typography>
              }
			  arrow
      		>
				<Button variant="contained" sx={styles.planActionButton} onClick={() => {
					if (!textCopied) {
						fetch(`/checkout/paymentLink?plan=${plan.id}`)
							.then((response) => {
								response.text().then((url) => {
										navigator.clipboard.writeText(url);
										setTextCopied(true);
										setTimeout(() => {
											setTextCopied(false);
										}, 2000);
									});
								})
								.catch((error) => {
									console.error(error);
								});
						}
					}}> {textCopied ? "Copied" : "Share buy link"}</Button>
				</Tooltip>
          </Box>
          );
    } else {
        if(isSelectedPlan) {
            let canBuyRenew = currentPlan.canBuyRenewLicense;
            let buttonStyle = canBuyRenew ? styles.planActionButton : styles.disablePlanActionButton;
            let buttonId = canBuyRenew ? plan.id + "-renew-button" : plan.id + "-current-plan-button";
            let buttonText = canBuyRenew ? 'Renew' : 'Current plan';
            let href = canBuyRenew ? '/checkout/renew' : '#';
            actionButton = (
                <Box sx={styles.planButtonsContainer}>
                    <Button variant="contained" id={buttonId} sx={buttonStyle} href={href}>{buttonText}</Button>
                </Box>);
        } else {
            if (isInferiorPlan(plan, currentPlan, allPlans)) {
                actionButton = (
                    <Box sx={styles.planButtonsContainer}>
                        <Button variant="contained" id={plan.id + "-disabled-subscribe-button"} sx={styles.disablePlanActionButton}>
                            Subscribe
                        </Button>
                    </Box>
                );
            } else {
                actionButton = (
                    <Box sx={styles.planButtonsContainer}>
                        <Button variant="contained" id={plan.id + "-subscribe-button"}
                            sx={styles.planActionButton} href={`/checkout/upgrade?plan=${plan.id}`}>
                            Upgrade
                        </Button>
                    </Box>
                );
            }
        }
    }

    const isActivePlan = !(currentPlan.isExpired || isCancelled);

    return (
        <Card variant="outlined" sx={{ border: isSelectedPlan && isActivePlan ? '2px solid gray' : '1px solid light-gray' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6" sx = {{textTransform: "uppercase"}}>{plan.planName}</Typography>
                <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, textAlign: 'center' }}>
                    {plan.description}
                </Typography>
                <Typography variant="h5" color="primary" sx={{ marginTop: 2 }}>
                    {plan.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">per month</Typography>
                <Typography variant="body2" sx={{ marginTop: 3 }}>
                    Monthly credits: {Number(plan.credits).toLocaleString()}
                </Typography>

                {actionButton}


            </CardContent>
        </Card>
    );
}

function isInferiorPlan(plan, currentPlan, allPlans) {
    const plans = allPlans.map(p => p.planName.toLowerCase())
    let planIndex = plans.indexOf(plan.planName.toLowerCase());
    let currentPlanIndex = plans.indexOf(currentPlan.planName.toLowerCase());
    return planIndex < currentPlanIndex;
}

export default SubscriptionPlanCard;