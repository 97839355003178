import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, Container, Grid, Typography, List, ListItem } from "@mui/material";
import { BENEFITS } from '../Constants';

const styles = {
    li: {
        display: 'list-item',
        padding: 0.1
    }
};

export default function Home() {

    const { hash } = useLocation();
    useEffect(() => {
        if (hash === '#credits') {
            const element = document.querySelector(hash);
            if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }, [hash]);

    return <>
        <Container>

            <Container sx={{paddingTop:"2em"}}>
                <Typography variant="h5" sx={{paddingBottom:"0.1em"}}>What is Oxygen AI Positron? </Typography>
                <Typography>
                    The Oxygen AI Positron service uses the OpenAI platform to help technical documentation writers with features
                    such as content generation, change suggestions, and translation capabilities. The service is accessed from within
                    Oxygen XML Editor through the <a href="https://www.oxygenxml.com/doc/help.php?product=editor&amp;platform=standalone&amp;pageId=ai_positron" target="_blank" rel="noreferrer"> AI Positron Assistant add-on</a>.
                </Typography>
            </Container>

            <Container sx={{paddingTop:"3em"}}>
                <Typography sx={{ textAlign: "center", paddingBottom: "0.5em", fontSize: "1.75em" }}>User Benefits</Typography>
                <Typography sx={{paddingBottom: "0.5em"}}>With the set of unique features it offers and the seamless integration with the editing environment, Oxygen AI Positron Assistant is indeed a <b>game&#x2011;changer</b> for technical writers, marketing professionals, developers, and all other Oxygen users. </Typography>
                <Grid container spacing={3}>
                    {BENEFITS.map((value) => (
                        <Grid key={value.id} item sm={4}>
                            <Card sx={{ minWidth: 275, border:"none", boxShadow:"none" }}>
                                <CardContent>
                                    <Typography variant="h5" sx={{ textAlign: "center", paddingBottom:"0.1em" }}><img src={value.image} alt={value.title}/></Typography>
                                    <Typography variant="h5" sx={{ textAlign: "center", paddingBottom:"0.1em" }}>{value.title}</Typography>
                                    <Typography variant="body2"> {value.content}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Container sx={{paddingTop:"2em"}} id="credits">
                <Typography variant="h5" sx={{paddingBottom:"0.1em"}}>Credits</Typography>
                <Typography>
                    Each user has a limit to the number of requests that are sent to the AI server each month and this is managed through the use of credits.
                    The 1-month free evaluation plan includes 500,000 credits. If you have consumed all of your free credits or the evaluation plan expires,
                    the service can only be continued by purchasing a <a href="/subscriptions" style={{ fontSize: '1em' }}>subscription plan</a>.
                </Typography>
                <Typography>
                    Each invoked action consumes credits with the AI prompt associated with
                    the action, the input text (editor text, selection from the editor text, chat messages, or
                    user input), and the AI response.
                </Typography>
                <Typography sx={{paddingTop:"0.2em"}}>
                    Credits are calculated using a weighted model based on <a href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them" target="_blank" rel="noreferrer">OpenAI tokens</a>:
                </Typography>
                <List sx={{ listStyleType: 'disc', pl: 4 }}>
                        <ListItem sx={styles.li}>
                            <strong>GPT-3.5-Turbo:</strong>
                            <List sx={{ listStyleType: 'circle', pl: 4 }}>
                                <ListItem sx={styles.li}>1 input token = 0.25 credits</ListItem>
                                <ListItem sx={styles.li}>1 output token = 0.75 credits</ListItem>
                            </List>
                        </ListItem>
                        <ListItem sx={styles.li}>
                            <strong>GPT-4:</strong>
                            <List sx={{ listStyleType: 'circle', pl: 4 }}>
                                <ListItem sx={styles.li}>1 input token = 15 credits</ListItem>
                                <ListItem sx={styles.li}>1 output token = 30 credits</ListItem>
                            </List>
                        </ListItem>
                        <ListItem sx={styles.li}>
                            <strong>GPT-4-Turbo:</strong>
                            <List sx={{ listStyleType: 'circle', pl: 4 }}>
                                <ListItem sx={styles.li}>1 input token = 5 credits</ListItem>
                                <ListItem sx={styles.li}>1 output token = 15 credits</ListItem>
                            </List>
                        </ListItem>
                        <ListItem sx={styles.li}>
                            <strong>GPT-4o:</strong>
                            <List sx={{ listStyleType: 'circle', pl: 4 }}>
                                <ListItem sx={styles.li}>1 input token = 2.50 credits</ListItem>
                                <ListItem sx={styles.li}>1 output token = 7.50 credits</ListItem>
                            </List>
                        </ListItem>
                        <ListItem sx={styles.li}>
                            <strong>GPT-4o-Mini:</strong>
                            <List sx={{ listStyleType: 'circle', pl: 4 }}>
                                <ListItem sx={styles.li}>1 input token = 0.15 credits</ListItem>
                                <ListItem sx={styles.li}>1 output token = 0.30 credits</ListItem>
                            </List>
                        </ListItem>
                    </List>
                    <Typography sx={{ paddingTop: "0.5em" }}>
                        <strong>Note:</strong> The credit costs listed above apply to the standard usage of these models. However, please be aware that for some specific snapshots or versions of these models, the credit cost may vary slightly.
                        <br /><br />
                        <strong>Input tokens</strong> refer to the associated prompt and input text you provide to the model, while <strong>output tokens</strong> refer to the response generated by the AI. The total number of tokens used will determine the credits consumed for a given interaction.
                    </Typography>
            </Container>
        </Container>
    </>;
}