export const HOST_URL = window.location.origin;
export const API_URL = HOST_URL + "/api";
export const HOME_PATH = '/home';
export const SUBSCRIPTIONS_PATH = '/subscriptions';

export const DETECTING_SUBSCRIPTION_PLAN_MSG = 'Detecting subscription plan';
export const FAILED_RETREIVE_SUBSCRIPTION_PLAN_MSG = 'Failed to retrieve subscription plan';
export const FAILED_RETREIVE_ALL_SUBSCRIPTION_PLANS_MSG = 'Failed to retrieve subscription plans';
export const CURRENT_PLAN_LABEL = 'Current plan';
export const EVALUATION_PLAN = 'EVALUATION';

export const BENEFITS = [
    {
        id: "Enhanced_Productivity",
        image: "static/images/kf_positron_enhanced_productivity.png",
        title: "Enhanced Productivity",
        content: `The main benefit of using AI tools is the productivity gain. This comes not only from the access to AI-generated text alone, but it is the result of the seamless integration between the AI functionality and the editing tool.`
    },
    {
        id: "Fine_Tuned_Actions",
        image: "static/images/kf_positron_actions.png",
        title: "Fine-Tuned Actions",
        content: `A variety of fine-tuned and tested AI actions are provided to allow you to benefit from the AI support, by covering tasks like improving existing content, translating to various languages, or proposing various pieces of text.`
    },
    {
        id: "You_Are_in_Control",
        image: "static/images/kf_positron_control.png",
        title: "You Are in Control",
        content: `Stay in full control of the changes made to your documents and see exactly what is modified using the powerful visual XML-aware diff comparison tool provided by Oxygen.`
    },
    {
        id: "Tailored_AI_Responses_with_RAG",
        image: "static/images/kf_positron_rag.png",
        title: "Tailored AI Responses with RAG",
        content: `By leveraging the Retrieval-Augmented Generation (RAG) optimization process, the AI delivers more meaningful, and project-specific responses by incorporating information from your current project into its context.`
    },
    {
        id: "Vision_Model_Support",
        image: "static/images/kf_positron_vision.png",
        title: "Vision Model Support",
        content: `The AI Positron Assistant plugin supports Vision models to read and interpret images, as well as streamlining DITA topic creation by allowing you to specify context, instructions, and images for generating precise drafts.`
    },
    {
        id: "Custom_AI_Actions",
        image: "static/images/kf_positron_prompts.png",
        title: "Custom AI Actions",
        content: `Record your changes made in the editor as a set of instructions for the AI and save the final result as a custom AI action or favorite prompt that can be easily reused and shared with others. You can also create custom AI actions using a convenient document template in JSON format.`
    },
    {
        id: "Refined_Writing",
        image: "static/images/kf_positron_writing.png",
        title: "Refined Writing",
        content: `The AI Positron Assistant can serve as a quick proofreading tool, catching grammar and spelling errors, as well as offering suggestions for sentence structure and style improvements.`
    },
    {
        id: "Multi_Language_Translation",
        image: "static/images/kf_positron_translation.png",
        title: "Multi-Language Translation",
        content: `If you are a technical writer working with international audiences, our add-on can assist you in translating content into multiple languages, while also maintaining consistency and preserving the structure of your documents.`
    },
    {
        id: "AI_Fixes",
        image: "static/images/kf_positron_ai_fixes.png",
        title: "AI-Fixes",
        content: `Simplify error fixing and optimize your workflow with AI-powered support. Even when you encounter validation problems, the innovative feature known as AI-Fix will identify and apply a solution for these types of issues.`
    },
    {
        id: "AI_Powered_X_Path_Functions",
        image: "static/images/kf_positron_xpath.png",
        title: "AI-Powered XPath Functions",
        content: `Access AI-based XPath functions from various languages, including XSLT, XQuery, and Schematron. The AI Positron Assistant helps you integrate AI functionality seamlessly for XML refactoring actions, custom Author actions, Schematron validation rules, SQF quick-fixes, and any scenario utilizing XPath.`
    },
    {
        id: "Marketing_Tool",
        image: "static/images/kf_positron_marketing.png",
        title: "Marketing Tool",
        content: `Make your content more engaging and informative. Get immediate assistance in tailoring marketing materials to various audience segments or in converting your content into something more accessible for non-technical readers.`
    },
    {
        id: "Never_Ending_Creativity",
        image: "static/images/kf_positron_creativity.png",
        title: "Never-Ending Creativity",
        content: `Say goodbye to writer's block as you effortlessly generate drafts, outlines, or even complete sections of technical documents, manuals, or articles. It can also provide you with ideas and new approaches for your content.`
    }
];