import { List, ListItemButton, ListItemText, ListItemIcon, Box, Drawer, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import React, { useEffect, useState } from 'react';
import CustomAppBar from "../components/PositronAppBar.jsx";
import EmailValidation from "../components/EmailValidation.jsx";
import { handleLogout } from "../util/logout.js";
import Home from "../components/Home.jsx";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { HOME_PATH, SUBSCRIPTIONS_PATH } from "../Constants.js";
import CookieConsent from "../components/CookieConsent.jsx";

const drawerWidth = 240;

function Body({ status, user, location}) {

  if (status === 'loading') {
    return <Typography variant="h5" component="div">Loading...</Typography>;
  }

  if (status === 'error') {
    return <Typography variant="h5" component="div">Error</Typography>;
  }

  if (status === 'unauthorized') {
    return <Box sx={{ paddingTop: 5 }}><Home/></Box>;
  }
  
  if (!user.mailValidated) {
    return (<EmailValidation user={user} />);
  }
  
  const isSelected = (path) => location.pathname === path;
  const isSubscriptionsPath = isSelected(SUBSCRIPTIONS_PATH);
  const isHomePath = isSelected(HOME_PATH);
  
  return (
    <Box sx={{ display: 'flex'}}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          zIndex:998
        }} >
        <Box sx={{ paddingTop: 7 }}>
          <List>
            <ListItemButton component={Link} to={HOME_PATH} selected={isHomePath} disableRipple>
              <ListItemIcon>
                <HomeIcon color={getDrawerItemColor(isHomePath)} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="inherit" color={getDrawerItemColor(isHomePath)}>
                  Home
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton component={Link} to={SUBSCRIPTIONS_PATH} selected={isSubscriptionsPath} disableRipple>
              <ListItemIcon>
                <MonetizationOnIcon color={getDrawerItemColor(isSubscriptionsPath)} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="inherit" color={getDrawerItemColor(isSubscriptionsPath)}>
                  Subscriptions
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box sx={{ paddingTop: 5, margin: 'auto' }}>
        <Outlet />
      </Box>
    </Box>
  );

  function getDrawerItemColor(isSelected) {
    return isSelected ? "primary" : "action";
  }

}

function Footer() {
  return (
    <Typography variant="body1" sx={{ textAlign: "center", padding: "2em 2em" }}>
      <a target="_blank" rel="noreferrer" href="https://www.oxygenxml.com/aipositron/user_terms.html">
        Terms of Service</a> | <a target="_blank" rel="noreferrer" href="https://www.oxygenxml.com/privacy_policy.html">
        Privacy Policy</a>
    </Typography>);
}


function MainPage() {
  const [status, setStatus] = useState('loading');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch('/api/users/me');

        if (!response.ok) {
          if (response.status === 401) {
            setStatus('unauthorized');
          } else {
            setStatus('error');
          }
          return;
        }

        const data = await response.json();
        setUser(data);
        setStatus('success');

        if (location.pathname === '/') {
          navigate(HOME_PATH);
        }
      } catch (error) {
        setStatus('error');
      }
    };

    // No need to check auth status if we're showing the consent form
    checkAuthStatus();
  }, [location.pathname, navigate]);


  return (
    <Box sx={{ display: 'flex', flexDirection: "column", minHeight: "100vh" }}>
      <CustomAppBar loggedIn={status === 'success'} onLogout={handleLogout} user={user}/>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Body status={status}
              user={user}
              location={location} />
      </Box>
      <Footer sx={{ marginTop: "auto" }} />
      <CookieConsent />
    </Box>
  );
}

export default MainPage;
