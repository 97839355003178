import { Box, Paper, Typography } from "@mui/material";
import CustomAppBar from "../components/PositronAppBar";
import { useLocation } from "react-router-dom";

const styles = {
    box: {
        display: 'inline-flex',
        height: '100%',
        width: '100%'
    },

    paper: {
        padding: '50px',
        maxWidth: 'sm',
        margin: '50px auto 0 auto',
        textAlign: 'center',
        boxShadow: 'none'
    },
};

export default function Info() {
    const urlSearchParam = new URLSearchParams(useLocation().search);
    const email = decodeURIComponent(urlSearchParam.get("email"));
    const code = decodeURIComponent(urlSearchParam.get("code"));

    let title = "Unexpected error";
    let message = "Unable to redirect to buy page. Please contact our support team."
    if(code === 'subscription-active') {
        title = "Subscription Already Active";
        message = 'There is an active subscription linked to ' + email + '.';
    } else if (code === 'user-not-found') {
        title = "Account Not Found"
        message = 'There is no account associated with the email address ' + email + "."
    }

    return (
        <Box sx={styles.box}>
            <CustomAppBar hasError={true} loggedIn={false}/>
            <Paper elevation={3} sx={styles.paper}>
                <Typography variant="h5">{title}</Typography><br/>
                <Typography variant="body1">{message}</Typography>
            </Paper>
        </Box>
    );

}